import React from 'react'

function Work() {
    return (
        <>
            <section className="md:p-10">
                <h2 className="font-sans dark:text-sky-50 capitalize font-bold text-blue-500 text-center mb-7 text-3xl md:text-5xl">Who I Work With</h2>

                <div className="flex flex-col md:flex-row flex-wrap gap-6 py-4 px-3 justify-center">

                    <div className="w-full md:w-1/2 lg:w-1/4 p-3 md:mb-3 mb-5 rounded-md">
                        <h3 className="dark:text-sky-50 capitalize font-bold text-2xl text-blue-500">Musicians🎙</h3>

                        <p className="font-serif dark:text-indigo-50 text-indigo-800">
                        I've led the development of a <a href='https://djezisa.co.za' className='underline dark:decoration-purple-300 decoration-purple-800'>music platform tailored for independent musicians</a>. With my expertise, I can build a cutting-edge platform that empowers your fans to support your artistry through innovative technology. Let's amplify your reach and connect you with your audience like never before!</p>

                    </div>

                    <div className="w-full md:w-1/2 lg:w-1/4 p-3 md:mb-3 mb-5 rounded-md">
                        <h3 className="dark:text-sky-50 capitalize font-bold text-2xl text-blue-500" title="Search Engine Optimization">YouTube Content Creators📹</h3>

                        <p className="font-serif dark:text-indigo-50 text-indigo-800">
                        Are you seeking fresh avenues to monetize your YouTube content? Let me assist you in implementing top-notch strategies to boost your earnings, allowing you to focus on crafting exceptional content for your audience. Let's elevate your YouTube journey together!
                        </p>

                    </div>




                    <div className="w-full md:w-1/2 lg:w-1/4 p-3 md:mb-3 mb-5 rounded-md">
                        <h3 className="dark:text-sky-50 capitalize font-bold text-2xl text-blue-500">Small Businesses🏬</h3>

                        <p className="font-serif dark:text-indigo-50 text-indigo-800">
                        I've collaborated with numerous small businesses, honing my expertise in enhancing overall customer service. Let's elevate your customer experience to new heights together!
                        </p>

                    </div>



                </div>


            </section>
        </>
    )
}

export default Work