import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode, faDollarSign, faInfoCircle } from '@fortawesome/free-solid-svg-icons'

function Services() {
    return (
        <>
            <section className="md:p-10">
                <h2 className="font-sans dark:text-sky-50 capitalize font-bold text-blue-500 text-center mb-7 text-3xl md:text-5xl">What I can do for you</h2>

                <div className="flex flex-col md:flex-row flex-wrap gap-6 py-4 px-3 justify-center">

                    <div className="w-full md:w-1/2 lg:w-1/4 p-7 md:mb-3 mb-5 rounded-xl dark:shadow-blue-600 shadow-md shadow-sky-300">
                        <h3 className="dark:text-sky-50 capitalize font-bold text-2xl text-blue-500 border-b-2 border-sky-300 dark:border-blue-700">Tailored Web Development <FontAwesomeIcon icon={faCode} /></h3>

                        <p className="font-serif dark:text-indigo-50 text-indigo-800">
As a web developer, I've built many websites from scratch. I know just the right tech to create a custom solution for you. Let's bring your vision to life!
                        </p>

                    </div>

                    <div className="w-full md:w-1/2 lg:w-1/4 p-7 md:mb-3 mb-5 rounded-xl dark:shadow-blue-600 shadow-md shadow-sky-300">
                        <h3 className="dark:text-sky-50 capitalize font-bold text-2xl text-blue-500 border-b-2 border-sky-300 dark:border-blue-700" title="Search Engine Optimization">SEO <FontAwesomeIcon icon={faInfoCircle} /></h3>

                        <p className="font-serif dark:text-indigo-50 text-indigo-800">
                        When it comes to ranking on search engines, knowing what your audience is looking for is key. I'm adept at crafting SEO-friendly websites and can also guide you on content strategies to boost your visibility online. Let's get your content noticed by the right audience!
                        </p>

                    </div>




                    <div className="w-full md:w-1/2 lg:w-1/4 p-7 md:mb-3 mb-5 rounded-xl dark:shadow-blue-600 shadow-md shadow-sky-300">
                        <h3 className="dark:text-sky-50 capitalize font-bold text-2xl text-blue-500 border-b-2 border-sky-300 dark:border-blue-700">Content Monetization <FontAwesomeIcon icon={faDollarSign} /></h3>

                        <p className="font-serif dark:text-indigo-50 text-indigo-800">
                        If you're aiming to build a career as a content creator, monetizing your content is crucial. I specialize in implementing effective monetization strategies for your platform while prioritizing user experience and respecting privacy. Let's maximize your earning potential while keeping your audience engaged and satisfied!
                        </p>

                    </div>



                </div>


            </section>
        </>
    )
}

export default Services
