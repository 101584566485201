import React from "react"
import addToMailchimp from 'gatsby-plugin-mailchimp'


function Newsletter() {

    const [email, setEmail] = React.useState('');
    const [formStatus, setFormStatus] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [formLoading, setFormLoading] = React.useState(false);

    const handleSubmit = (e) => {
        e.preventDefault()
        setFormLoading(true)

        addToMailchimp(email)
            .then(data => {
                console.log(data)
                setFormLoading(false)

                if (Notification.permission === 'granted') {
                    new Notification('Newsletter Subscription', {
                        body: 'Thank you for subscribing to my newsletter',
                    });
                }

                document.getElementById('newsletter-form').reset();
                setEmail('');
                
                
            })
            .catch(err => {
                setErrorMessage(err)
                setFormLoading(false)
                setEmail('');
                document.getElementById('newsletter-form').reset();
            })
    }
    return (
        <>
            <section className="dark:bg-indigo-900 mt-4 bg-violet-300 p-4 rounded-md" id="exclusive">
                <h2 className="font-sans mt-7 dark:text-sky-50 capitalize font-bold text-center text-blue-800 text-3xl">Subscribe to my Newsletter</h2>

                <form onSubmit={handleSubmit} id="newsletter-form">
                    <p className="p-6 text-center font-serif">
                        Subscribe to my newsletter to get notified when I publish new content and updates.
                    </p>

                    {errorMessage && <p>{errorMessage}</p>}


                    <div className="container">
                        <div className="md:text-center">
                            <input type="email" required value={email} onChange={
                                (e) => setEmail(e.target.value)
                            } className="w-full md:w-1/3 mx-auto dark:bg-sky-100 text-indigo-900 focus:outline-violet-600 placeholder:dark:placeholder-fuchsia-600  p-3 border-2 border-sky-400 rounded-md" placeholder="djembeleza@techentrepreneur.com" />
                            <button className="w-full md:w-1/5 p-3 mt-3 font-medium bg-blue-400 text-white rounded-md" type="submit">Subscribe</button>

                        </div>

                    </div>

                </form>

            </section>
        </>
    )
}

export default Newsletter
