import React from 'react'

const HeroSection = () => {
    return (
        <>
            <section className='container p-4 mx-auto'>
                <div className='flex flex-col md:flex-row h-96 md:min-h-screen'>

                    <div className="w-full md:w-1/2 mx-auto my-auto">
                        <h1 className='dark:text-sky-50 text-center capitalize mb-2 font-bold text-blue-900 text-4xl'>Content Monetization Specialist</h1>
                        <p className="dark:text-sky-50 text-center text-blue-900 mb-7 text-xl"> I help content creators <span className="underline dark:decoration-blue-500 decoration-indigo-800 font-sans">monetize&#128176;</span> their online presence</p>


                        <div className="flex flex-row">
                            <a href='https://telegram.me/djembeleza' target='_blank' rel='noreferrer noopener' className='w-full text-center mx-auto md:w-3/6 py-3 md:py-6 md:px-3 dark:bg-sky-200 rounded text-2xl dark:text-indigo-700 bg-blue-700 text-sky-100'>Contact Me Now</a>

                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default HeroSection
