import * as React from "react"
import { Link, graphql } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
// import { faGoogle } from '@fortawesome/free-brands-svg-icons'
import { useContent, useStatus } from 'gatsby-plugin-monetization'

import Services from "../components/services"
import HeroSection from "../components/hero"
import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Newsletter from "../components/newsletter"
import Work from "../components/work"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  React.useEffect(() => {
    if (Notification.permission === 'default') {
      Notification.requestPermission().then((result) => {
        if (result === 'granted') {
          new Notification('Welcome to my website', {
            body: 'You have granted permission to receive notifications',
          });
        } else {
          
        }
      });
    }

  }, []);
  


  const message = `Welcome to my tech blog where I share what is web monetization and it's benefits.`


  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        <Bio />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location}>
      <Seo title="Content Monetization Specialist | Web Developer for Content Creators"
        description={message}
      />

      <HeroSection />
      <Services />
      <Work />

      <section className="mt-8 p-3">


        <h2 className="font-sans text-center dark:text-sky-50 capitalize font-bold text-blue-500 text-3xl">Latest Posts</h2>
        <ol style={{ listStyle: `none` }} className="lg:w-4/6 lg:mx-auto p-4">
          {posts.map(post => {
            const title = post.frontmatter.title || post.fields.slug

            return (
              <li key={post.fields.slug} className="border-b-4 py-4 my-3">
                <article
                  className="post-list-item text-slate-700 dark:text-slate-50"
                  itemScope
                  itemType="http://schema.org/Article"
                >
                  <header>
                    <h2>
                      <Link className="dark:text-slate-50 text-slate-700 text-2xl font-sans underline dark:decoration-gray-300 decoration-purple-900" to={post.fields.slug} itemProp="url">
                        <span itemProp="headline">{title}</span>
                      </Link>
                    </h2>
                    <small className="dark:text-slate-50 text-slate-700">{post.frontmatter.date}</small>
                  </header>
                  <section>
                    <p
                      className="text-slate-600 dark:text-sky-200 font-serif"
                      dangerouslySetInnerHTML={{
                        __html: post.frontmatter.description || post.excerpt,
                      }}
                      itemProp="description"
                    />
                  </section>
                </article>
              </li>
            )
          })}
        </ol>


      </section>

      <Newsletter />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
